import { Box } from "@material-ui/core";
import { FavoriteRounded } from "@material-ui/icons";

export default {

  page_home_title: (
    <>
      <Box
        sx={{
          maxWidth: '600px'
        }}
      >
        Melhorando <span style={{color: '#FFDE5A'}}>vidas</span>
      </Box>
    </>
  ),
  page_home_subtitle: (
    <>
      {/* Nossa <b>missão</b> é maximizar a vida e o cotidiano na Terra, criando soluções com propósito para problemas e desafios reais. Queremos fazer a diferença na vida do máximo de pessoas possível. */}
      Construímos tecnologias e soluções que proporcionam maior qualidade de vida e avanços para a sociedade, impulsionando também o sucesso de empresas.
    </>
  ),
  page_home_button_banner: 'Ver soluções',
  page_home_button_contact: 'Fale conosco',

  modal_contact_title: 'Entraremos em contato',
  modal_contact_subtitle: 'Preencha os campos abaixo',
  modal_contact_field_name: 'Nome',
  modal_contact_field_email: 'E-mail',
  modal_contact_field_contact: 'Contato',
  modal_contact_field_message: 'Mensagem',
  modal_contact_field_message_placeholder: 'Sobre o que gostaria de falar? Alguma de nossas soluções?',
  modal_contact_button: 'Enviar',
  modal_contact_message_success: 'Mensagem enviada com sucesso',
  modal_contact_message_error: 'Tivemos um problema para enviar a mensagem. Tente novamente!',

  page_home_section_solutions_box_title: 'Nossos produtos',
  page_home_section_solutions_box_description_frametask: 'Auxiliamos no gerenciamento de fluxo de caixa pessoal, objetivos e despesas.',
  page_home_section_solutions_box_description_legin: 'Automatizamos documentos e contratos com aplicação de Legal Design.',
  page_home_section_solutions_box_description_levo: 'Aumentamos a mobilidade, facilitando aquisição de veículos.',
  page_home_section_solutions_box_description_ekvalu: 'Geramos acesso a produtos e serviços relacionados ao mundo equestre.',
  page_home_section_solutions_box_description_gryb: 'Ajudamos pequenas e médias empresas a gerenciar seu fluxo de caixa.',
  page_home_section_characteristics_title: 'Algumas de nossas características',
  page_home_section_characteristics_subtitle: 'Nossa abordagem prioriza a inovação e a busca por soluções impactantes, com um compromisso inabalável com a excelência. Estamos determinados a enfrentar desafios diversos e proporcionar experiências únicas a nossos usuários e clientes.',
  page_home_section_characteristics_first_column_title: 'Empresas e soluções com propósito',
  page_home_section_characteristics_first_column_subtitle: 'A diversificação de segmentos acontece de forma natural na medida que enxergamos a necessidade de solucionar alguma dor.',
  page_home_section_characteristics_second_column_title: 'Nos desafiamos a cada dia',
  page_home_section_characteristics_second_column_subtitle: 'Acompanhamos e estudamos os dados de nossos usuários e soluções a todo momento, gerando melhorias e inovações constantes.',
  page_home_section_characteristics_third_column_title: (<> Amamos tecnologia e engenharia </>),
  page_home_section_characteristics_third_column_subtitle: 'Somos inquietos em construir soluções que envolvam grandes desafios. Estamos constantemente atuando em soluções que envolvam software e hardware.',

  page_home_section_ceo_title: `”Se estivermos melhorando a vida das pessoas, estamos na direção certa!”`,
  page_home_section_ceo_subtitle: 'Patrick Faciroli, Fundador & CEO da App&Web',

  page_home_section_solutions_title: 'Estrutura corporativa',
  page_home_section_solutions_subtitle: 'Nossas soluções são distribuídas em empresas quem controlamos e em ferramentas operadas diretamente por nós.',
  page_home_section_solutions_title_section_companies: 'Empresas',
  page_home_section_solutions_title_section_solutions: 'Soluções',

  page_home_section_history_title: 'Nossa história até agora',
  page_home_section_history_subtitle: (
    <>
      Nascemos em <b>2017</b> com recursos mínimos dentro de um quarto, em Franca, no interior do estado de São Paulo. Desde sempre movidos por uma paixão insana por tecnologia e engenharia.
      <br /><br />
      Nossa primeira solução foi a <b><a href="https://levo.com.br" style={{ color: '#000' }}>Levo</a></b> em 2017 que consiste em uma empresa que intermedia a relação entre compradores e vendedores de automóveis, auxiliando na mobilidade e em 2023, lançamos a <b><a href="https://ekvalu.com.br" style={{ color: '#000' }}>Ekvalu</a></b> que trata-se de um marketplace de produtos equestres.
      <br /><br />
      A empresa conta com um time estratégico e com escritórios tanto em São Paulo - SP quanto no interior do estado, em Franca - SP.
      <br /><br />
      Lançamos duas ferramentas em <b>2024</b>, sendo a <a href="https://frametask.appandwebstudio.com.br" style={{ color: '#000' }}>Frametask</a> para pessoas físicas e a <a href="https://legin.appandwebstudio.com.br" style={{ color: '#000' }}>Legin</a> para pessoas jurídicas.
      <br /><br />
      Nossa visão é de sermos uma Big tech brasileira podendo ajudar a mudar diretamente a vida das pessoas e empresas com a aplicação de tecnologia e engenharia em soluções estratégicas.
    </>
  ),

  page_home_footer_coporate_name: 'App&Web Tecnologia LTDA',

  modal_solution_visit_label: 'Acessar',
  modal_solution_label_name: 'Nome',
  modal_solution_label_segment: 'Segmento',

  short_description_ekvalu: 'Marketplace equestre',
  description_ekvalu: 'Queremos mudar a forma como as pessoas no geral se relacionam com o Cavalo, que é um animal fantástico. Somos um marketplace único e nichado para levar os produtos relacionados a este animal ao máximo de pessoas possíveis.',

  short_description_levo: 'Classificados de veículos',
  description_levo: 'Temos como objetivo proporcionar uma experência de compra e venda de veículos única para todos os elos da cadeia, seja revendedor/vendedor ou comprador.',

  short_description_frametask: 'Controle financeiro, objetivos e tarefas pessoais',
  description_frametask: 'O brasileiro não consegue administrar seu dinheiro e por conseqência atingir seus sonhos. Somos uma plataforma para gerenciamento de fluxo financeiro, sonhos/objetivos e tarefas do dia a dia. Com nossa IA, conseguimos viabilizar uma condição de vida melhor para os usuários, inclusive ajudando a concretizar seus objetivos.',

  short_description_legin: 'Criação e gestão de documentos',
  description_legin: 'Documentos jurídicos não precisam ser compostos somente por texto. A facilidade no entendimento do documento é o mais importante. Somos um ecossistema de design para documentos jurídicos.',

};